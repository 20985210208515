<template>
  <div>
    <dialog class="modal" id="onboarding_modal">
      <div class="modal-box px-1 bg-theme-bg rounded-none sm:min-h-[500px] sm:min-w-[700px] ">
        <form method="dialog">
          <div class="w-full">
            <button class="absolute right-2 top-2">
              <img class="hidden sm:block" src="~assets/onboarding/close-large.svg" :alt="$t('accessibility.close')" />
              <img class="sm:hidden" src="~assets/onboarding/close-small.svg" :alt="$t('accessibility.close')" />
            </button>
          </div>
        </form>
        <div class="flex flex-col gap-2 mt-3 mx-2">
          <div ref="carousel" id="carousel" class="carousel w-full">
            <div id="slide1" class="carousel-item">
              <div class="carousel-item-content">
                <div class="flex justify-center w-full">
                  <img
                      v-if="platform == 'bikeable'"
                      src="~assets/bikeable-mascot-neutral.svg"
                      class="h-[220px] -translate-x-[15%]"
                      :alt="$t('accessibility.mascot_neutral')" />
                  <img
                      v-else
                      src="~assets/walkable-mascot-neutral.svg"
                      class="h-[220px]"
                      :alt="$t('accessibility.mascot_neutral')" />
                </div>
                <h3>{{ $t('onboarding.' + platform + '_title') }}</h3>
                <p>{{ $t('onboarding.' + platform + '_text') }}</p>
              </div>
            </div>
            <div id="slide2" class="carousel-item" v-if="countryCode == 'ch'">
              <div class="carousel-item-content">
                <div class="flex justify-center w-full">
                  <img
                      src="~assets/onboarding/bikeable-walkable.svg"
                      class="h-[220px]"
                      :alt="$t('accessibility.bikeable_and_walkable')" />

                </div>
                <h3>{{ $t('onboarding.two_platforms_title') }}</h3>
                <p>{{ $t('onboarding.two_platforms_text') }}</p>
                <ul>
                  <li>bikeable.ch</li>
                  <li>walkable.ch</li>
                </ul>
              </div>
            </div>
            <div id="slide3" class="carousel-item">
              <div class="carousel-item-content">
                <h3>{{ $t('onboarding.newsletter_title') }}</h3>
                <SettingsNewsletter v-if="showNewsletter && auth.authenticated" />
              </div>
            </div>
            <div id="slide4" class="carousel-item">
              <div class="carousel-item-content">
                <div class="flex justify-center w-full">
                  <img
                      src="~assets/onboarding/respect.svg"
                      class="h-[140px] m-[40px]"
                      :alt="$t('accessibility.respect')" />

                </div>
                <h3>{{ $t('onboarding.respect_title') }}</h3>
                <p>{{ $t('onboarding.respect_text') }}</p>
                <NuxtLink class="text-link" target="_blank" to="/guidelines">{{ $t('onboarding.guidelines_title') }}</NuxtLink>
              </div>
            </div>
            <div id="slide5" class="carousel-item relative">
              <div class="carousel-item-content">
                <div class="flex justify-center w-full">
                  <img
                      v-if="platform == 'bikeable'"
                      src="~assets/bikeable-profilbild.svg"
                      class="h-[100px] m-[60px]"
                      :alt="$t('accessibility.profile_image')" />
                  <img
                      v-else
                      src="~assets/walkable-profilbild.svg"
                      class="h-[100px] m-[60px]"
                      :alt="$t('accessibility.profile_image')" />

                </div>
                <h3>{{ $t('onboarding.profile_title') }}</h3>
                <p>{{ $t('onboarding.profile_text') }}</p>
                <NuxtLink class="text-link" target="_blank" to="/profile">{{ $t('onboarding.profile_title') }}</NuxtLink>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <button class="font-bold text-3xl" :class="currentSlide > 1 ? 'visible' : 'invisible'" @click="goToPreviousSlide">{{ $t('onboarding.button_back') }}</button>
            <button v-if="currentSlide < numberOfSlides" class="font-bold text-3xl" @click="goToNextSlide">{{ $t('onboarding.button_continue') }}</button>
            <button v-else class="font-bold text-3xl" @click="closeOnboardingModal">{{ $t('accessibility.close') }}</button>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>
<script setup lang="ts">
const platform = usePlatformSettings().platformName;
const countryCode = usePlatformSettings().countryCode
const currentSlide = ref(1);
const carousel = ref();
const showNewsletter = ref(false);
const numberOfSlides =  countryCode.value != 'ch' ? 4 : 5;
const auth = useAuth();

onMounted(() => {
  setTimeout(() => { showNewsletter.value = true; }, 2000);
});

function goToNextSlide() {
  if (currentSlide.value < numberOfSlides+1) {
    currentSlide.value++;
    scrollToSlide(currentSlide.value);
  }
}

function goToPreviousSlide() {
  if (currentSlide.value > 0) {
    currentSlide.value--;
    scrollToSlide(currentSlide.value);
  }
}

function scrollToSlide(slideId: number) {
  for (let i = 1; i <= slideId; i++) {
    const carousel = document.getElementById(`carousel`) as HTMLElement;
    let carouselWidth = carousel.clientWidth;
    // Images are numbered from 1 to 4 so thats why we substract 1
    let targetImage = slideId - 1;
    let targetXPixel = (carouselWidth * targetImage);
    carousel.scrollTo(targetXPixel, 0);
  }
}

function closeOnboardingModal() {
  (document.getElementById("onboarding_modal") as HTMLFormElement).close();
}

function openOnboardingModal() {
  (document.getElementById("onboarding_modal") as HTMLFormElement).showModal();
}

</script>
<style lang="scss" scoped>

.step-theme-primary + .step-theme-primary:before, .step-theme-primary:after {
  @apply bg-theme-primary text-white transition-all duration-500;
}

.carousel-item {
  @apply w-full min-h-[150px];
}

.carousel-item-content {
  @apply flex flex-col items-start justify-start gap-1 w-full px-1;
}
</style>