<template>
    <svg
        class="h-[29px] w-[36px] sm:h-[37.5px] sm:w-[60px]"
        width="81"
        height="50"
        viewBox="0 0 81 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path class="fill-theme-secondary" fill-rule="evenodd" clip-rule="evenodd" d="M80.069 4.17241C80.069 4.7247 79.6213 5.17241 79.069 5.17241H1C0.447715 5.17241 0 4.7247 0 4.17241V1C0 0.447715 0.447717 0 1 0H79.069C79.6213 0 80.069 0.447716 80.069 1V4.17241Z"/>
      <path class="fill-theme-secondary" fill-rule="evenodd" clip-rule="evenodd" d="M80.069 26.5865C80.069 27.1388 79.6213 27.5865 79.069 27.5865H1C0.447715 27.5865 0 27.1388 0 26.5865V23.4141C0 22.8618 0.447717 22.4141 1 22.4141H79.069C79.6213 22.4141 80.069 22.8618 80.069 23.4141V26.5865Z" />
      <path class="fill-theme-secondary" fill-rule="evenodd" clip-rule="evenodd" d="M80.069 48.9996C80.069 49.5518 79.6213 49.9996 79.069 49.9996H1C0.447715 49.9996 0 49.5518 0 48.9996V45.8271C0 45.2749 0.447717 44.8271 1 44.8271H79.069C79.6213 44.8271 80.069 45.2749 80.069 45.8271V48.9996Z"/>

    </svg>
</template>
<style lang="postcss" scoped>

button {
  path {
    @apply duration-100;
  }

}

button:hover {
  path {
    @apply fill-theme-primary;
  }
}
</style>

